import React, { createContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { AdSearch } from "./AdSearch";
import { useMetadata, useSqliteAdCache } from "hooks/query";
import { useSection } from "hooks/section";
import { NotFoundError } from "components/ErrorBoundary";
import { useState } from "react";

export const SearchContext = createContext({});

export const isBoosted = (searchState, boostedSubjects) => {
    return boostedSubjects && boostedSubjects.split(";").some((r) => searchState.subjects.data.includes(r));
};

export default function SearchProvider({ adCache = true, children }) {
    console.log("ADCACHE", adCache);
    const [searchParams] = useSearchParams();
    const metadataQuery = useMetadata();
    let sqlLiteAdCache = null;
    if(adCache) {
        sqlLiteAdCache = useSqliteAdCache();
    }
    const { sectionEnum } = useSection();
    
    console.log("SECTION ENUM", sectionEnum);

    const createFilter = (
        header,
        filterName,
        adProperty,
        operator,
        type,
        maxCount,
        showInSearch,
        menuSort,
        data,
        lookupItems,
        keyField
    ) => {
        let filter = {
            header: header,
            filterName: filterName,
            adProperty: adProperty,
            operator: operator,
            type: type,
            maxCount: maxCount,
            showInSearch: showInSearch,
            menuSort: menuSort,
            data: data,
            lookupItems: lookupItems,
            keyField: keyField,
            add: function (value) {
                this.data.push(value);
            },
            clear: function () {
                this.data = [];
            },
            remove: function (value) {
                this.data = [...this.data].filter((d) => d !== value);
            },
            lookups: function (filters, relations) {
                // console.log("LOOKUPS", filters, relations);
                let child = this;
                let relation = relations.find((f) => f.childHeader === header);
                if (relation === null || relation === undefined) {
                    return child.lookupItems;
                }
                let parent = filters.find((f) => f.header === relation.parentHeader);

                let items = child.lookupItems.filter((ci) => {
                    let flag = false;

                    for (let parentData of parent.data) {
                        let parentRealItem = parent.lookupItems.find((f) => f[parent.keyField] === parentData);
                        if (parentRealItem === undefined) continue;
                        // console.log("PARENTREALITEM", child, relation, parentRealItem);
                        if (!relation.hasOwnProperty("parentKey")) continue;
                        let parentKey = parentRealItem[relation.parentKey];
                        // let parentItem = parent.lookupItems.find(pi => pi.$id === parentRealItem.$id);
                        // if (parentItem && parentItem[relation.parentValue] === ci[relation.childValue])
                        //     flag = true;
                        if (!parentKey || parentKey === ci[relation.childValue]) flag = true;
                    }
                    return flag;
                });
                return items;
            },
            childLookups: function (filters, relations) {
                let parent = this;
                let relation = relations.find((f) => f.parentHeader === this.header);
                if (relation === null || relation === undefined || filters === undefined) return [];
                let child = filters.find((f) => f !== undefined && f.header === relation.childHeader);
                if (child === null || child === undefined) return [];
                let tempLookup = child.lookups(filters, relations);
                return child === undefined ? [{ value: "Ett" }, { value: "Två" }, { value: "Tre" }] : tempLookup;
            },
        };

        return filter;
    };

    const counties = (metadataQuery.data?.locations?.$values ?? [])
        .filter((location) => location.extra !== null)
        .map((location) => {
            return (metadataQuery.data?.counties?.$values ?? []).find((county) => {
                return county.key === location.extra;
            });
        });

    const deduplicated = counties.filter((value, index, self) => index === self.findIndex((t) => t.key === value.key));
    const sortedCounties = deduplicated.sort((a, b) => a.value.localeCompare(b.value));

    let searchState = {
        sections: createFilter(
            "SEKTION",
            "sections",
            "sectionEnum",
            "and",
            2,
            1,
            false,
            0,
            [sectionEnum?.toString()],
            metadataQuery.data?.sections?.$values ?? [],
            "extra",
            "key"
        ),
        adtypes: createFilter(
            "KATEGORIER",
            "adTypes",
            "adTypeEnum",
            "and",
            1,
            1,
            true,
            0,
            searchParams.get("adTypes")?.split(";") ?? [],
            metadataQuery.data?.adTypes?.$values ?? [],
            "extra",
            "key"
        ),
        subjects: createFilter(
            "HUVUDOMRÅDE",
            "subjects",
            "subject_strings",
            "and",
            1,
            1,
            true,
            1,
            searchParams.get("subjects")?.split(";") ?? [],
            metadataQuery.data?.subjects?.$values ?? [],
            "value",
            "key"
        ),
        tags: createFilter(
            "UNDERKATEGORIER",
            "tags",
            "tag_strings",
            "or",
            1,
            1,
            true,
            2,
            searchParams.get("tags")?.split(";") ?? [],
            metadataQuery.data?.tags?.$values ?? [],
            "value",
            "key"
        ),
        educationtypes: createFilter(
            "UTBILDNINGSTYPER",
            "educationTypes",
            "educationtype_strings",
            "and",
            3,
            1,
            true,
            3,
            searchParams.get("educationTypes")?.split(";") ?? [],
            metadataQuery.data?.educationTypes?.$values ?? null,
            "value",
            "key"
        ),
        counties: createFilter(
            "LÄN",
            "counties",
            "county_strings",
            "and",
            2,
            1,
            true,
            2,
            searchParams.get("counties")?.split(";") ?? [],
            sortedCounties,
            "value",
            "key"
        ),
        locations: createFilter(
            "ORT",
            "locations",
            "locations_strings",
            "or",
            2,
            1,
            true,
            4,
            searchParams.get("locations")?.split(";") ?? [],
            metadataQuery.data?.locations?.$values ?? [],
            "value",
            "key"
        ),
        educators: createFilter("UTBILDNINGSFÖRETAG", "companies", "companyname", "and", 1, 1, false, 0, [], [], null),
        studyplaces: createFilter(
            "STUDIEPLATSER",
            "availableStudentSlots",
            "availableStudentSlots",
            "and",
            2,
            1,
            false,
            0,
            searchParams.get("availableStudentSlots") ?? "",
            [],
            "z",
            "key"
        ),
        // fulltext: createFilter(
        //     "FULLTEXT",
        //     "fulltext",
        //     "shortdescription",
        //     "and",
        //     2,
        //     1,
        //     false,
        //     searchParams.get("fullt"),
        //     0,
        //     [],
        //     "å"
        // ),
        freetext: searchParams.get("freetext") ?? "",
        relations: [
            {
                childHeader: "HUVUDOMRÅDE",
                childValue: "extra",
                parentHeader: "SEKTION",
                parentValue: "extra",
                parentKey: "extra",
            },
            {
                childHeader: "UNDERKATEGORIER",
                childValue: "extra",
                parentHeader: "HUVUDOMRÅDE",
                parentValue: "key",
                parentKey: "key",
            },
            {
                childHeader: "LÄN",
                childValue: "section",
                parentHeader: "SEKTION",
                parentValue: "all",
                parentKey: "all",
            },
            {
                childHeader: "ORT",
                childValue: "extra",
                parentHeader: "LÄN",
                parentValue: "key",
                parentKey: "key",
            },
        ],
    };

    searchState.filters = [
        searchState.sections,
        searchState.adtypes,
        searchState.subjects,
        searchState.tags,
        searchState.educationtypes,
        searchState.counties,
        searchState.locations,
        searchState.studyplaces,
    ];

    console.log("SEARCHSTATE FILTERS", searchState.filters);
    
    let results = sqlLiteAdCache && sqlLiteAdCache.isSuccess
        ? AdSearch(searchState.filters, searchState.relations, searchState.freetext, sqlLiteAdCache.data) ?? []
        : [];
   
    const booleanCompare = (a, b) => {
        return Number(b) - Number(a);
    };

    const adComparer = (a, b) => {
        return booleanCompare(isBoosted(searchState, a.boosted_subjects), isBoosted(searchState, b.boosted_subjects));
    };

    if (searchState.subjects.data.length > 0) {
        results = results.sort((a, b) => adComparer(a, b));
    }

    const store = {
        results: results,
        searchState: searchState,
        // db: sqlLiteAdCache.data,
        // setDb: setDb,
    };

    return <SearchContext.Provider value={store}>{children}</SearchContext.Provider>;
}
