import React from "react";
import { useLocation, Outlet } from "react-router-dom";
import Helmet from "react-helmet";

import Affiliate from "components/Affiliate";
import Navigation from "components/Navigation";
import Footer from "components/Footer";
import CookieModal from "./CookieModal";
import ErrorBoundary from "./ErrorBoundary";

import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";

import "../index.css";
import "../bootstrap-modifications.css";
import { useEffect } from "react";
import Seo from "components/Seo";

function isProduction() {
    return window.location.origin.includes("utbildningssidan.se");
}

export default function App() {
    const location = useLocation();

    useEffect(() => {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: "pageview",
            });
        }
    }, [location]);

    return (
        <>
            <Seo title={"Utbildningssidan"}></Seo>
            {isProduction() &&
                <Helmet>
                    <script defer data-domain="utbildningssidan.se" src="https://plausible.io/js/plausible.js"></script>
                </Helmet>
            }

            <CookieModal />
            <Affiliate />
            <Navigation />
            <ErrorBoundary key={location.pathname}>
                <Outlet />
            </ErrorBoundary>
            <Footer />
        </>
    );
}
