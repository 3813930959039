import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, BrowserRouter as Router, Routes, Navigate } from "react-router-dom";

import App from "components/App/";

import { CookieProvider } from "components/CookieProvider";
import CenterSpinner from "components/CenterSpinner";
import SearchProvider from "components/AdSearchManager/SearchContext";
import NotFound from "routes/NotFound";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ErrorBoundary from "components/ErrorBoundary";

if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN, // hack because a space found its way there
        integrations: [new BrowserTracing()],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
        release: import.meta.env.VITE_COMMIT_REF,
    });
}
const Home = React.lazy(() => import("routes/Home"));
const Presentation = React.lazy(() => import("routes/Presentation"));
const Presentions = React.lazy(() => import("routes/Pesentations"));
const Ad = React.lazy(() => import("routes/Ad"));
const RedirectAddSectionAd = React.lazy(() => import("routes/RedirectAddSectionAd"));
const RedirectAddSectionPresentation = React.lazy(() => import("routes/RedirectAddSectionPresentation"));
const RedirectAddSectionStudyFact = React.lazy(() => import("routes/RedirectAddSectionStudyFact"));
const Section = React.lazy(() => import("routes/Section"));
const StudyFact = React.lazy(() => import("routes/StudyFact"));
const Conversation = React.lazy(() => import("routes/Qa"));
const ConversationCandidate = React.lazy(() => import("routes/QaCandidate"));
const ProfessionalRole = React.lazy(() => import("routes/ProfessionalRole"));

const queryClient = new QueryClient();

ReactDOM.render(
    <React.StrictMode>
        <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
                <CookieProvider>
                    <Router>
                        <Routes>
                            <Route path="*" element={<NotFound />} />

                            <Route path="/" element={<App />}>
                                <Route
                                    index
                                    element={
                                        <Suspense fallback={<CenterSpinner />}>
                                            <Home />
                                        </Suspense>
                                    }
                                />

                                <Route path="/eg/*" element={<Navigate to="/eftergymnasial-utbildning" />} />
                                <Route path="/gy/*" element={<Navigate to="/gymnasium" />} />

                                <Route
                                    path="/qa/:conversationId"
                                    exact
                                    element={
                                        <Suspense fallback={<CenterSpinner />}>
                                            <Conversation />
                                        </Suspense>
                                    }
                                />

                                <Route
                                    path="/qacandidate/:conversationId"
                                    exact
                                    element={
                                        <Suspense fallback={<CenterSpinner />}>
                                            <ConversationCandidate />
                                        </Suspense>
                                    }
                                />

                                {/* <Route
                                    path="/presentation/:slug"
                                    element={
                                        <Suspense fallback={<CenterSpinner />}>
                                            <RedirectAddSectionPresentation />
                                        </Suspense>
                                    }
                                /> */}

                                <Route
                                    path="/:section/:slug"
                                    element={
                                        <Suspense fallback={<CenterSpinner />}>
                                            <SearchProvider adCache={false}>
                                                <Presentation />
                                            </SearchProvider>
                                        </Suspense>
                                    }
                                />

                                <Route
                                    path="/:section/utbildning/:slug"
                                    element={
                                        <Suspense fallback={<CenterSpinner />}>
                                            <SearchProvider adCache={false}>
                                                <Ad />
                                            </SearchProvider>
                                        </Suspense>
                                    }
                                />

                                <Route
                                    path="/:section/yrkesroll/:slug"
                                    element={
                                        <Suspense fallback={<CenterSpinner />}>
                                            <SearchProvider>
                                                <ProfessionalRole />
                                            </SearchProvider>
                                        </Suspense>
                                    }
                                />

                                <Route
                                    path="/utbildning/:slug"
                                    element={
                                        <Suspense fallback={<CenterSpinner />}>
                                            <RedirectAddSectionAd />
                                        </Suspense>
                                    }
                                />

                                <Route
                                    path="/studiefakta/:slug"
                                    element={
                                        <Suspense fallback={<CenterSpinner />}>
                                            <RedirectAddSectionStudyFact />
                                        </Suspense>
                                    }
                                />

                                <Route
                                    path="/:section/studiefakta/:slug"
                                    element={
                                        <Suspense fallback={<CenterSpinner />}>
                                            <SearchProvider>
                                                <StudyFact />
                                            </SearchProvider>
                                        </Suspense>
                                    }
                                />

                                <Route
                                    path="/presentationer/:section"
                                    element={
                                        <Suspense fallback={<CenterSpinner />}>
                                            <SearchProvider>
                                                <Presentions />
                                            </SearchProvider>
                                        </Suspense>
                                    }
                                />

                                <Route
                                    path="/:section"
                                    exact
                                    element={
                                        <Suspense fallback={<CenterSpinner />}>
                                            <SearchProvider>
                                                <Section />
                                            </SearchProvider>
                                        </Suspense>
                                    }
                                />
                            </Route>
                        </Routes>
                    </Router>
                </CookieProvider>
            </QueryClientProvider>
        </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById("root")
);
