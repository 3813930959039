import React from "react";
import { Container } from "react-bootstrap";

import { useMetadata } from "hooks/query";

import facebook from "../assets/facebook.svg";
import "./Footer.css";

export default function Footer() {
    const { data, isSuccess, isLoading } = useMetadata();

    return (
        <footer className="Footer">
            <SocialMediaBanner />
            <Container>
                <div className="sidebyside">
                    <Info />
                    <Links />
                </div>
                <Copyright />
            </Container>
        </footer>
    );
}

function SocialMediaBanner() {
    const { data, isSuccess, isLoading } = useMetadata();
    return (
        <div className="socialmediabanner">
            <Container>
                <p>{data && data.footerElements.$values.find(v => v.key === "footerSocialmedia").value}</p>
                <ul>
                    <li>
                        <a href="https://www.facebook.com/utbildningssidan/" target="_blank">
                            <img alt="Facebook" src={facebook} width="30px" height="30px" />
                        </a>
                    </li>
                </ul>
            </Container>
        </div>
    );
}

function Info() {
    const { data, isSuccess, isLoading } = useMetadata();
    return (
        <div className="info">
            <img
                alt="Logo"
                src="https://d33wubrfki0l68.cloudfront.net/cc925f0a11a0a2384d3394756701fc9d11f7d22b/3c83b/img/studentmedia-b.svg"
            />
            <h1>{data && data.footerElements.$values.find(v => v.key === "footerSlogan").value}</h1>
            <p>
                {data && data.footerElements.$values.find(v => v.key === "footerIngress").value}
            </p>
        </div>
    );
}

function Links() {
    const { data, isSuccess, isLoading } = useMetadata();
    return (
        <ul className="links">
            <li>
                <h2>Produkter</h2>
                <ul>
                    <li>
                        <a href="#">Utbildningssidan</a>
                    </li>
                    <li>
                        <a href="https://www.piraja.se" target="_blank">Piraja.se</a>
                    </li>
                    <li>
                        <a href="https://www.studentmedia.se/se/magasin" target="_blank">Magasin</a>
                    </li>
                </ul>
            </li>
            <li>
                <h2>Information</h2>
                <ul>
                    <li>
                        <a href="https://www.studentmedia.se/se/soktjanster/#utbildningssidan" target="_blank">Annonsera</a>
                    </li>
                    <li>
                        <a href="https://www.studentmedia.se/se/kundservice" target="_blank">Kundservice</a>
                    </li>
                    <li>
                        <a href="https://www.studentmedia.se/se/om-oss/" target="_blank">Om oss</a>
                    </li>
                    <li>
                        <a href="https://www.studentmedia.se/se/villkor/" target="_blank">Villkor</a>
                    </li>
                    <li>
                        <a href="https://www.studentmedia.se/se/cookies/">Cookies</a>
                    </li>
                </ul>
            </li>
            <li>
                <h2>Kontakt</h2>
                <ul>
                    <li>
                        <a href="#">{data && data.footerElements.$values.find(v => v.key === "footerAddress").value}</a>
                    </li>
                    <li>
                        <a href="#">{data && data.footerElements.$values.find(v => v.key === "footerEmail").value}</a>
                    </li>
                    <li>
                        <a href="#">{data && data.footerElements.$values.find(v => v.key === "footerOrgNo").value}</a>
                    </li>
                </ul>
            </li>
        </ul>
    );
}

function Copyright() {
    const { data, isSuccess, isLoading } = useMetadata();
    return (
        <div className="copyright">
            <p>{data && data.footerElements.$values.find(v => v.key === "footerCopyright").value}</p>
        </div>
    );
}
