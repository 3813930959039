import * as Sentry from "@sentry/react";
import React from "react";
import NotFound from "routes/NotFound";

import "./ErrorBoundary.css";
export class NotFoundError extends Error {
    constructor(message = "", ...args) {
        super(message, ...args);
    }
}

export default function SentryErrorBoundary({ children }) {
    return <Sentry.ErrorBoundary fallback={ErrorComponent}>{children}</Sentry.ErrorBoundary>;
}

function ErrorComponent({ error }) {
    if (error instanceof NotFoundError) {
        return <NotFound />;
    }

    if (error != null) {
        return (
            <div className="ErrorBoundary">
                <h1>Något gick fel.</h1>
                <p>{error?.message ?? "Inget felmeddelande"}</p>
            </div>
        );
    }

    return (
        <div className="ErrorBoundary">
            <h1>Något gick fel.</h1>
            <p>Inget felmeddelande</p>
        </div>
    );
}
