import { NotFoundError } from "components/ErrorBoundary";
import { useLocation, matchPath, useParams } from "react-router-dom";

import { useMetadata } from "./query";

const sections = {
    GYMNASIUM: 1,
    EFTERGYMASIAL_UTBILDNING: 2,
    STUDENTJOBB: 3,
    FRITIDSKURSER: 4,
    KOMPETENSUTVECKLING: 5,
};

function useSection() {
    let _sections = [
        {
            section: "kompetensutveckling",
            sectionEnum: 5,
            sectionUrl: "kompetensutveckling",
            sectionName: "Kompetensutveckling",
        },
        {
            section: "eftergymnasial-utbildning",
            sectionEnum: 2,
            sectionUrl: "eftergymnasial-utbildning",
            sectionName: "Eftergymnasial Utbildning",
        },
        { section: "gymnasium", sectionEnum: 1, sectionUrl: "gymnasium", sectionName: "Gymnasium" },
        { section: "studentjobb", sectionEnum: 3, sectionUrl: "traineeprogram", sectionName: "Studentjobb" },
        { section: "traineeprogram", sectionEnum: 3, sectionUrl: "traineeprogram", sectionName: "Studentjobb" },
    ];
    const params = useParams();
    console.log("params", params);
    let aParam = _sections.find((value) => value.section === params.section);
    console.log("params", "aParam", params, aParam);
    if (aParam == null) {
        console.log("OOMPFH...");
        return {
            sectionEnum: null,
            sectionUrl: null,
            sectionName: null,
        };
    }

    return {
        sectionEnum: aParam.sectionEnum,
        sectionUrl: aParam.sectionUrl,
        sectionName: aParam.sectionName,
    };

    // console.log("PARAMS", params);
    // if (metadataQuery.isSuccess) {
    //     console.log("SUCCESSFUL METADATA", metadataQuery.data.sections.$values);
    //     let section = metadataQuery.data.sections.$values.find((value) => {
    //         return value.key === (params.section === "studentjobb" ? "traineeprogram" : params.section);
    //     });

    //     console.log("FOUND SECTION", params.section, section);

    //     if (params.section && !section) {
    //         throw new NotFoundError();
    //     }

    //     if (section === undefined) {
    //         return {
    //             sectionEnum: null,
    //             sectionUrl: null,
    //             sectionName: null,
    //         };
    //     }

    //     return {
    //         sectionEnum: parseInt(section.extra),
    //         sectionUrl: section.key,
    //         sectionName: section.value,
    //     };
    // }

    // return {
    //     sectionEnum: null,
    //     sectionUrl: null,
    //     sectionName: null,
    // };
}

export { sections, useSection };
