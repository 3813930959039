export function AdSearch(filters, relations, freetext, db) {
    let section = filters.find((f) => f.header === "SEKTION");
    let subject = filters.find((f) => f.header === "HUVUDOMRÅDE");
    let tag = filters.find((f) => f.header === "UNDERKATEGORIER");
    let educationtype = filters.find((f) => f.header === "UTBILDNINGSTYPER");
    let adtype = filters.find((f) => f.header === "KATEGORIER");
    let county = filters.find((f) => f.header === "LÄN");
    let location = filters.find((f) => f.header === "ORT");
    let sqliteResult = [];

    let sectionData = section && section.data && section.data.length > 0 ? section.data[0] : null;
    let subjectData = subject && subject.data && subject.data.length > 0 ? JSON.stringify(subject.data) : null;
    let tagData = tag && tag.data && tag.data.length > 0 ? JSON.stringify(tag.data) : null;
    let adtypeData = adtype && adtype.data && adtype.data.length > 0 ? adtype.data[0] : null;
    let educationtypeData =
        educationtype && educationtype.data && educationtype.data.length > 0
            ? JSON.stringify(educationtype.data)
            : null;
    let countystringData = county && county.data && county.data.length > 0 ? JSON.stringify(county.data) : null;
    let locationstringData =
        location && location.data && location.data.length > 0 ? JSON.stringify(location.data) : null;
    let tokens = freetext.split(" ");
    let freetext1 = tokens.length > 0 ? tokens[0].trim() : null;
    let freetext2 = tokens.length > 1 ? tokens[1].trim() : null;
    let freetext3 = tokens.length > 2 ? tokens[2].trim() : null;

    const stmt = db.prepare(
        "SELECT title, companyname, logourl, keywords, shortdescription, urlbase, 'category', locations_strings, county_strings, educationtype_strings, tag_strings, tags, subject_strings, sectionEnum, adTypeEnum, xmin, availablestudentslots, boosted_subjects, deal_amount, deal_percentage, price " +
            "FROM ad " +
            "WHERE (sectionenum=:sectionenum " +
            `AND (:subject_strings IS NULL OR EXISTS (SELECT 1 FROM json_each(:subject_strings) WHERE value IN (SELECT value from json_each('["' || REPLACE(COALESCE(subject_strings, ''), ";", '","') || '"]')))) ` +
            `AND (:tag_strings IS NULL OR EXISTS (SELECT 1 FROM json_each(:tag_strings) WHERE value IN (SELECT value from json_each('["' || REPLACE(COALESCE(tag_strings, ''), ";", '","') || '"]')))) ` +
            "AND (:adtypeEnum IS NULL OR adtypeEnum=:adtypeEnum) " +
            `AND (:educationtype_strings IS NULL OR EXISTS (SELECT 1 FROM json_each(:educationtype_strings) WHERE value IN (SELECT value from json_each('["' || REPLACE(COALESCE(educationtype_strings, ''), ";", '","') || '"]')))) ` +
            "AND (:county_strings IS NULL OR (locations_strings = 'Distansutbildning' or EXISTS (SELECT 1 FROM json_each(:county_strings) WHERE value = county_strings))) " +
            "AND (:locations_strings IS NULL OR (locations_strings = 'Distansutbildning' OR EXISTS (SELECT 1 FROM json_each(:locations_strings) WHERE value = locations_strings))) " +
            "AND (:freetext1 IS NULL OR coalesce(lowercompanyname, '') || ' ' || coalesce(lowertitle, '') || ' ' || coalesce(lowerkeywords, '') || ' ' || coalesce(lowershortdescription, '') like '%' || :freetext1 || '%') " +
            "AND (:freetext2 IS NULL OR coalesce(lowercompanyname, '') || ' ' || coalesce(lowertitle, '') || ' ' || coalesce(lowerkeywords, '') || ' ' || coalesce(lowershortdescription, '') like '%' || :freetext2 || '%') " +
            "AND (:freetext3 IS NULL OR coalesce(lowercompanyname, '') || ' ' || coalesce(lowertitle, '') || ' ' || coalesce(lowerkeywords, '') || ' ' || coalesce(lowershortdescription, '') like '%' || :freetext3 || '%'))"
    );

    try {
        stmt.bind([
            sectionData,
            subjectData,
            tagData,
            adtypeData,
            educationtypeData,
            countystringData,
            locationstringData,
            freetext1,
            freetext2,
            freetext3,
        ]);    
    }
    catch (e) {
        console.log(e);
    }
    
    while (stmt.step()) {
        let o = stmt.get();

        sqliteResult.push({
            $id: "2",
            handlerType: 0,
            name: o[0],
            companyname: o[1],
            img: o[2],
            keywords: o[3],
            shortdescription: o[4],
            urlbase: o[5],
            category: null,
            locations_strings: o[6],
            county_strings: o[7],
            educationtype_strings: o[8],
            tag_strings: null,
            tags: null,
            subject_strings: o[9],
            sectionEnum: o[10],
            adTypeEnum: o[11],
            xmin: o[12],
            availableStudentSlots: o[13],
            boosted_subjects: o[14],
            dealamount: o[18],
            dealpercentage: o[19],
            price: o[20]
        });
    }
    stmt.free();

    return sqliteResult;
}
