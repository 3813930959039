import { useContext } from "react";
import { useQuery } from "react-query";

import { useSection } from "./section";
import { useParams } from "react-router-dom";
import initSqlJs from "sql.js";
import { SearchContext } from "components/AdSearchManager/SearchContext";

function useMetadata() {
    return useQuery(
        "metadata",
        () =>
            fetch(import.meta.env.VITE_BACKEND_URL + "/Gateway/GetMetadataVm")
                .then((res) => res.json())
                .then((data) => {
                    return {
                        ...data,
                        counties: {
                            $values: data.counties.$values.map((county) => {
                                return { ...county, section: "1", all: "X" };
                            }),
                        },
                    };
                }),
        {
            staleTime: 5 * 60 * 1000,
        }
    );
}

function useAdEvents(adId) {
    return useQuery(
        ["adevents", adId],
        () => fetch(`${import.meta.env.VITE_BACKEND_URL}/Foretag/AdEvent/${adId}`).then((res) => res.json()),
        {
            enabled: adId !== undefined,
        }
    );
}

export function mylower(value) {
    return value.toLowerCase();
}

function useSqliteAdCache() {
    return useQuery(
        "sqliteAdCache",
        () =>
            fetch(import.meta.env.VITE_BACKEND_URL + "/Gateway/AdCache").then(async (res) => {                
                // const SQL = await initSqlJs({ locateFile: (file) => `https://sql.js.org/dist/${file}` });
                const SQL = await initSqlJs({
                    locateFile: (file) => {                        
                        const fileUrl = `${window.location.origin}/${file}`;
                        console.log(`Fetching sql.js from ${fileUrl}`);
                        return fileUrl;
                    },
                });
                const buf = await res.arrayBuffer();
                const db = new SQL.Database(new Uint8Array(buf));
                db.create_function("mylower", mylower);
                db.exec("ALTER TABLE ad ADD lowertitle TEXT;");
                db.exec("ALTER TABLE ad ADD lowercompanyname TEXT;");
                db.exec("ALTER TABLE ad ADD lowerkeywords TEXT;");
                db.exec("ALTER TABLE ad ADD lowershortdescription TEXT;");
                db.exec("UPDATE ad SET lowertitle = replace(replace(replace(lower(title), 'Å', 'å'), 'Ä', 'ä'), 'Ö', 'ö');");
                db.exec("UPDATE ad SET lowercompanyname = replace(replace(replace(lower(companyname), 'Å', 'å'), 'Ä', 'ä'), 'Ö', 'ö');");
                db.exec("UPDATE ad SET lowerkeywords = replace(replace(replace(lower(keywords), 'Å', 'å'), 'Ä', 'ä'), 'Ö', 'ö');");
                db.exec("UPDATE ad SET lowershortdescription = replace(replace(replace(lower(shortdescription), 'Å', 'å'), 'Ä', 'ä'), 'Ö', 'ö');");
                return db;
            }),
        {
            staleTime: 5 * 60 * 1000,
        }
    );
}

function useAdDetail(slug) {
    return useQuery(
        ["adDetail", slug],
        () => fetch(import.meta.env.VITE_BACKEND_URL + "/Gateway/GetAdBySlug?slug=" + slug).then((res) => res.json()),
        {
            staleTime: 5 * 60 * 1000,
            enabled: slug !== undefined,
        }
    );
}

function useAdsChaoticOrder() {
    const { sectionUrl } = useSection();
    console.log("HMM", sectionUrl, window.location);
    return useQuery(
        ["ads", sectionUrl],
        () =>
            fetch(
                `${import.meta.env.VITE_BACKEND_URL}/Gateway/GetPromotedAdsInChaoticOrderBySection/us/${sectionUrl}`
            ).then((res) => res.json()),

        {
            staleTime: 5 * 60 * 1000,
        }
    );
}

function useFeaturedPresentations() {
    const { sectionUrl } = useSection();
    return useQuery(
        ["featuredPresentations_" + sectionUrl],
        () =>
            fetch(`${import.meta.env.VITE_BACKEND_URL}/Gateway/GetFeaturedPresentations/${sectionUrl}`).then((res) =>
                res.json()
            ),

        {
            staleTime: 5 * 60 * 1000,
        }
    );
}
function usePresentations() {
    const { sectionUrl } = useSection();
    return useQuery(
        ["Presentations_" + sectionUrl],
        () =>
            fetch(`${import.meta.env.VITE_BACKEND_URL}/Gateway/GetPresentationsBySection/${sectionUrl}`).then((res) =>
                res.json()
            ),

        {
            staleTime: 5 * 60 * 1000,
        }
    );
}

function usePresentation() {
    const { commissionerId } = useParams();

    return useQuery(
        ["presentation", commissionerId],
        () =>
            fetch(
                `${
                    import.meta.env.VITE_BACKEND_URL
                }/Gateway/GetPresentationByCommissionerId/?commissionerId=${commissionerId}`
            ).then((res) => res.json()),
        {
            staleTime: 5 * 60 * 1000,
        }
    );
}
function usePresentationBySlug() {
    const { slug } = useParams();

    return useQuery(
        ["presentationBySlug", slug],
        () =>
            fetch(`${import.meta.env.VITE_BACKEND_URL}/Gateway/GetPresentationBySlug/?slug=${slug}`).then((res) =>
                res.json()
            ),
        {
            staleTime: 5 * 60 * 1000,
        }
    );
}

function useAdsByCustomer() {
    const { slug } = useParams();
    return useQuery(
        ["adsByCustomer", slug],
        () =>
            fetch(`${import.meta.env.VITE_BACKEND_URL}/Gateway/GetStudentjobbVMsById/?slug=${slug}`).then((res) =>
                res.json()
            ),

        {
            staleTime: 5 * 60 * 1000,
        }
    );
}
function useTag(name) {
    return useQuery(
        ["tag", name],
        () => {
            if (name === null) return null;
            return fetch(`${import.meta.env.VITE_BACKEND_URL}/Gateway/GetTagByName/?Name=${name}`).then((res) =>
                res.json()
            );
        },
        {
            staleTime: 5 * 60 * 1000,
        }
    );
}
function useQa(publicId) {
    return useQuery(
        ["qa", publicId],
        () => {
            return fetch(`${import.meta.env.VITE_BACKEND_URL}/Gateway/Qa/${publicId}`).then((res) => res.json());
        },
        {
            staleTime: 5 * 60 * 1000,
        }
    );
}

function useProfessionalRole(slug) {
    return useQuery(
        ["professionalrole", slug],
        () => fetch(`${import.meta.env.VITE_BACKEND_URL}/Gateway/ProfessionalRole/${slug}`)
            .then((res) => res.json())
    );
}

function usePresentationDefaultSection(slug) {
    return useQuery(
        ["presentationdefaultsection", slug],
        () => fetch(`${import.meta.env.VITE_BACKEND_URL}/Gateway/PresentationDefaultSection/${slug}`)
                .then((res) => res.json())
    );
}

export {
    useMetadata,
    useAdDetail,
    useAdsChaoticOrder,
    useFeaturedPresentations,
    usePresentation,
    useTag,
    useAdsByCustomer,
    usePresentationBySlug,
    useQa,
    useAdEvents,
    useSqliteAdCache,
    usePresentations,
    useProfessionalRole,
    usePresentationDefaultSection,
};
