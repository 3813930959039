import React, { useState, createContext } from "react";
import Cookies from "js-cookie";
import TagManager from "react-gtm-module";
import { useEffect } from "react";

const CookieContext = createContext();

function isProduction() {
    return window.location.origin.includes("utbildningssidan.se");
}

function CookieProvider({ children }) {
    const [accepted, setAccepted] = useState(Cookies.get("consent") === "true" ? true : false);

    useEffect(() => {
        if (Cookies.get("consent") === "true" && isProduction()) {
            TagManager.initialize({ gtmId: "GTM-K6822NX" });

            if (window.dataLayer) {
                window.dataLayer.push({
                    event: "pageview",
                });
            }
        }
    }, []);

    const accept = () => {
        Cookies.set("consent", "true", { expires: 90 });
        setAccepted(true);
        if (isProduction()) {
            TagManager.initialize({ gtmId: "GTM-K6822NX" });

            if (window.dataLayer) {
                window.dataLayer.push({
                    event: "pageview",
                });
            }
        }
    };

    return <CookieContext.Provider value={{ accepted, accept }}>{children}</CookieContext.Provider>;
}

export { CookieContext, CookieProvider };
