import { useMetadata } from "hooks/query";
import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import CenterSpinner from "./CenterSpinner";
import { CookieContext } from "./CookieProvider";

export default function CookieModal() {
    const { accept, accepted } = useContext(CookieContext);
    const [modalShow, setModalShow] = useState(accepted ? false : true);

    const { data, isLoading } = useMetadata();

    return (
        <Modal show={modalShow} size="lg" centered>
            <Modal.Header>
                <Modal.Title>Dags för fika med nödvändiga kakor?<img src="https://res.cloudinary.com/hm7cvv3hp/image/upload/w_150/v1660813196/us/cookie-removebg-preview.png"></img></Modal.Title>                
            </Modal.Header>
            <Modal.Body>                
                {isLoading && <CenterSpinner short />}
                <p>En kaka förbättrar alltid en fikastund!
                Genom att godkänna nödvändiga cookies tillåter du att Utbildningssidan.se sparar data som dock inte är kopplade till dig som individ. </p>
                <p>Vh Kakmonstret</p> 
                <img src ="https://res.cloudinary.com/hm7cvv3hp/image/upload/w_150/v1661245380/us/shutterstock_2076408982.png"></img>
            </Modal.Body>
            <Modal.Footer>               
                <Button
                    onClick={() => {
                        accept();
                        setModalShow(false);
                    }}
                >
                    Godkänn
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
