import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link, Navigate, useParams } from "react-router-dom";

const NotFound = () => {
    const { section } = useParams();

    if (section && section.includes(".aspx")) {
        return <Navigate to="/" />;
    }

    return (
        <Container style={{ marginTop: "100px" }}>
            <Row>
                <Col md={7} xs={12}>
                    <Card>
                        <img
                            src="https://res.cloudinary.com/hm7cvv3hp/image/upload/v1661176522/us/man-1989145_640.png"
                            width={"300px"}
                            height={"auto"}
                        ></img>
                        <Card.Body>
                            <p>
                                <b>
                                    Sorry! Den här sidan har gått i pension. Det kan bero på att den inte längre finns
                                    eller att den flyttat på sig. Letar du efter en utbildning? Klicka på knapparna
                                    nedan för att ta dig vidare mot din drömutbildning!
                                </b>
                            </p>
                            <Row style={{ display: "flex", justifyContent: "space-around" }}>
                                <Link
                                    to="/"
                                    className="btn btn-primary col-xs-12 col-md-3 text-white"
                                    style={{ width: "min-content", whiteSpace: "nowrap" }}
                                >
                                    Start sidan{" "}
                                </Link>

                                <Link
                                    to="/eftergymnasial-utbildning?educationTypes=Distansutbildning"
                                    className="btn btn-primary btn btn-primary col-xs-12 col-md-3 text-white"
                                    style={{ width: "min-content", whiteSpace: "nowrap" }}
                                >
                                    Distansutbildning
                                </Link>

                                <Link
                                    to="/eftergymnasial-utbildning?educationTypes=Yrkesutbildning"
                                    className="btn btn-primary btn btn-primary col-xs-12 col-md-3 text-white"
                                    style={{ width: "min-content", whiteSpace: "nowrap" }}
                                >
                                    Yrkesutbildning
                                </Link>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default NotFound;
