import { useQueryClient, useMutation } from "react-query";

function useAddAffilicateRedirect() {
    const queryClient = useQueryClient();

    return useMutation(
        (values) => {
            console.log(values);
            return fetch(
                `${import.meta.env.VITE_BACKEND_URL}/Gateway/AffiliateRedirect?${new URLSearchParams(values)}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            ).then((response) => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response;
            });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("affiliateredirects");
            },
        }
    );
}

function useAddIncomingAffiliate() {
    const queryClient = useQueryClient();

    return useMutation(
        (values) => {
            return fetch(`${import.meta.env.VITE_BACKEND_URL}/Gateway/InitiateIncomingAffiliate`, {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                    "Content-Type": "application/json",
                },
            }).then((response) => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response;
            });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("incomingaffiliate");
            },
        }
    );
}

function useContactModalMutation() {
    return useMutation((values) =>
        fetch(`${import.meta.env.VITE_BACKEND_URL}/Gateway/SendQuestion`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
        }).then((response) => {
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            return response;
        })
    );
}

export function useBookingMutation() {
    return useMutation((values) =>
        fetch(`${import.meta.env.VITE_BACKEND_URL}/Gateway/SendBooking`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
        }).then((response) => {
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            return response;
        })
    );
}

function useAddQaPosting(publicId) {
    const queryClient = useQueryClient();

    return useMutation(
        (values) => {
            return fetch(`${import.meta.env.VITE_BACKEND_URL}/Gateway/Qa`, {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                    "Content-Type": "application/json",
                },
            }).then((response) => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response;
            });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["qa", publicId]);
            },
        }
    );
}

function useAddQaCandidatePosting(publicId) {
    const queryClient = useQueryClient();

    return useMutation(
        (values) => {
            return fetch(`${import.meta.env.VITE_BACKEND_URL}/Gateway/QaCandidate`, {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                    "Content-Type": "application/json",
                },
            }).then((response) => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response;
            });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["qa", publicId]);
            },
        }
    );
}

export { useAddAffilicateRedirect, useAddIncomingAffiliate, useContactModalMutation, useAddQaPosting, useAddQaCandidatePosting };
