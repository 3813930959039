import React from "react";
import { useAddIncomingAffiliate } from "hooks/queries";

function Affiliate() {

    let searchParams = new URLSearchParams(location.search);
    let affiliate = searchParams.get("affiliate");
    if(!affiliate)
        return null;
    let reference = searchParams.get("reference");
    if(!reference)
        return null;
    
    let incomingAffiliate = useAddIncomingAffiliate();
    incomingAffiliate.mutate({ affiliate: affiliate, reference: reference })

    const affiliatePair = document.cookie
        .split('; ')
        .find(row => row.startsWith('affiliate='));
    if(!affiliatePair)
        return null;
    const affiliateReference = JSON.parse(affiliatePair
        .split('=')[1]);
    if(affiliatePair) {
        return null;
    }

    return null;
}
export default Affiliate;
