import React from "react";

import { Spinner } from "react-bootstrap";

export default function CenterSpinner({ short }) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                margin: short ? "20px 0" : "100px 0",
            }}
        >
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div>
    );
}
