import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

import "./Navigation.css";

function Navigation() {
    let { pathname } = useLocation();

    const [expanded, setExpanded] = useState(false);
    function getLogo() {
        if (pathname.includes("/gymnasium") || pathname.includes("/eftergymnasial-utbildning"))
            return "https://res.cloudinary.com/hm7cvv3hp/image/upload/w_270/v1605518558/us/utbildningssidan-h-bl%C3%A5.png";

        if (pathname.includes("/studentjobb") || pathname.includes("/traineeprogram"))
            return "https://res.cloudinary.com/hm7cvv3hp/image/upload/w_270/v1599505885/us/Studentjobb_Av_Studentmedia_3.png";

        if (pathname.includes("/kompetensutveckling"))
            return "https://res.cloudinary.com/hm7cvv3hp/image/upload/w_270/v1616598926/us/Kompentensutveckling_horisontell__1_-removebg-preview.png";
        else
            return "https://res.cloudinary.com/hm7cvv3hp/image/upload/w_270/v1605518558/us/utbildningssidan-h-bl%C3%A5.png";
    }

    return (
        <>
            <Navbar expanded={expanded} className="Navigation fixed-top" expand="lg">
                <Link onClick={() => setExpanded(false)} className="nav-link" to="/">
                    <img src={getLogo()} alt="Logo" className="nav-logo" width="270px" />
                </Link>
                <Navbar.Toggle
                    onClick={() => setExpanded(expanded ? false : "expanded")}
                    aria-controls="basic-navbar-nav"
                />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Link onClick={() => setExpanded(false)} className="nav-link pl-1" to="/gymnasium">
                            <img
                                alt="gymnasium"
                                width="20px"
                                height="20px"
                                src="https://res.cloudinary.com/hm7cvv3hp/image/upload/v1599592640/us/utbildningssidan-b.png"
                            />
                            Gymnasium
                        </Link>
                        <Link
                            onClick={() => setExpanded(false)}
                            className="nav-link pl-1"
                            to="/eftergymnasial-utbildning"
                        >
                            <img
                                alt="eftergymnasial-utbildning"
                                width="20px"
                                height="20px"
                                src="https://res.cloudinary.com/hm7cvv3hp/image/upload/v1599592640/us/utbildningssidan-b.png"
                            />
                            Eftergymnasial utbildning
                        </Link>
                        <Link onClick={() => setExpanded(false)} className="nav-link pl-1" to="/kompetensutveckling">
                            <img
                                alt="kompetensutveckling"
                                width="20px"
                                height="20px"
                                src="https://res.cloudinary.com/hm7cvv3hp/image/upload/v1598873921/us/utbildning24-logo.png"
                            />
                            Kompetensutveckling
                        </Link>
                        <Link onClick={() => setExpanded(false)} className="nav-link pl-1" to="/studentjobb">
                            <img
                                alt="studentjobb"
                                width="20px"
                                height="20px"
                                src="https://res.cloudinary.com/hm7cvv3hp/image/upload/v1598874104/us/traineesearch-v.png"
                            />
                            Studentjobb
                        </Link>
                        <a
                            onClick={() => setExpanded(false)}
                            href="https://www.piraja.se/"
                            className="nav-link pl-1"
                            target="_blank"
                        >
                            <img
                                alt="Piraja"
                                width="20px"
                                height="20px"
                                src="https://res.cloudinary.com/hm7cvv3hp/image/upload/v1598872961/us/piraja-logo.png"
                            />
                            Blogg
                        </a>
                        <NavDropdown title="Nytta" id="basic-nav-dropdown" className="pl-1">
                            <NavDropdown.Item>
                                <Link to="/eftergymnasial-utbildning/studiefakta/cv-hjalp">CV-hjälp</Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                {" "}
                                <Link to="/eftergymnasial-utbildning/studiefakta/grundlaggande-behorighet">
                                    Grundläggande behörighet
                                </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Link to="/eftergymnasial-utbildning/studiefakta/grundlaggande-behorighet-for-yrkeshogskolan">
                                    Grundläggande behörighet för yrkeshögskolan
                                </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Link to="/eftergymnasial-utbildning/studiefakta/omradesbehorigheter-och-meritpoang">
                                    Områdesbehörigheter och meritpoäng
                                </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Link to="/eftergymnasial-utbildning/studiefakta/plugga-pa-distans">
                                    Plugga på distans
                                </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Link to="/eftergymnasial-utbildning/studiefakta/studentboende">Studentboende</Link>
                            </NavDropdown.Item>
                            {/* <NavDropdown.Item href="/studiefakta/studentforum">Studentforum</NavDropdown.Item> */}
                            <NavDropdown.Item>
                                <Link to="/eftergymnasial-utbildning/studiefakta/studera-utomlands">
                                    Studera utomlands
                                </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Link to="/eftergymnasial-utbildning/studiefakta/studieteknik">Studieteknik</Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Link to="/eftergymnasial-utbildning/studiefakta/yrkeshogskolan-snabba-vagar-till-jobb">
                                    Yrkeshögskolan snabba vägar till jobb
                                </Link>
                            </NavDropdown.Item>
                            {/* <NavDropdown.Item href="/studiefakta/ovrig-utbildningsinformation">Övrig utbildningsinformation</NavDropdown.Item> */}
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
}
export default Navigation;
